import { cancelApply } from "@/api/activiti";
export default {
  name: "processCancel",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    actBusinessId: String,
    procInstId: String
  },
  data() {
    return {
      submitLoading: false,
      modalCancelVisible: this.value,
      cancelForm: {
        reason: ""
      }
    };
  },
  methods: {
    init() {},
    show() {
      if (!this.actBusinessId) {
        this.$Message.error("请传入ActBusiness的ID");
        this.$emit("input", false);
        return;
      }
      if (!this.procInstId) {
        this.$Message.error("请传入流程实例的ID");
        this.$emit("input", false);
        return;
      }
      this.cancelForm.id = this.actBusinessId;
      this.cancelForm.procInstId = this.procInstId;
      this.modalCancelVisible = true;
    },
    handelSubmitCancel() {
      this.submitLoading = true;
      cancelApply(this.cancelForm).then(res => {
        this.submitLoading = false;
        if (res.success) {
          this.$Message.success("操作成功");
          this.$emit("on-submit", true);
          this.modalCancelVisible = false;
        }
      });
    },
    close() {
      this.modalCancelVisible = false;
    }
  },
  watch: {
    value(val) {
      if (val == true) {
        this.show();
      }
    },
    modalCancelVisible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();
  }
};